// Managed by Chef for staging.zap.ucla.edu

const config = {
  api: {
    url: 'https://staging.zap.ucla.edu'
  },
  nonSocketsApi: {
    url: 'https://staging.zap.ucla.edu/api'
  },
  opt: {
    url: 'https://staging.onlinepoll.ucla.edu'
  },
  email: 'mobilizelabs@oarc.ucla.edu',
}

module.exports = config
