import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRecoilValue } from 'recoil'
import { Dialog } from '@blueprintjs/core'
import styled from 'styled-components'

import { documentState } from 'v2/components/manager/document'
import config from 'config'

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    font-size: 12px;

    button {
        flex: 1;
        margin: 10px auto 0 auto;
    }
`

const PreviewButton = styled.button`
    border: 0;
    padding: 10px 20px;
    background: #ffd102;
    box-shadow: 2px 2px 0 0 rgba(40, 116, 174, 0.28);
    border-radius: 3px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
`

const PreviewDialog = ({ isOpen, onClose }) => {
    const [scale, setScale] = useState(1)
    const [loading, setLoading] = useState(true)
    const url = config.api.url + '/preview?org='
    const org = useSelector(state => state.navigation.org)
    const doc = useRecoilValue(documentState)
    let loadingWidget

    if (loading) {
        loadingWidget = (
            <p style={{
                position: 'absolute',
                top: '40px',
                margin: '20px',
                backgroundColor: 'white',
                textAlign: 'center',
            }}>
                Loading preview.<br />
                If this is your first time using the Live Preview,
                it can take up to a minute to fully load.<br />
                Please be patient.
            </p>
        )
    }

    return (
        <Dialog
            isOpen={isOpen}
            onOpened={() => setScale((window.innerHeight * 0.9 - 40) / 812)}
            onClose={onClose}
            title="Live Preview"
            style={{
                position: 'relative',
                width: 'calc((90vh - 40px) * 0.46 + 5px)',
                height: 'calc(90vh + 5px)',
                paddingBottom: 0,
                backgroundColor: 'transparent',
            }}
        >
            <iframe
                src={url + org + '&moduleId=' + doc._id}
                title="Live Preview"
                onLoad={() => setLoading(false)}
                style={{
                    position: 'absolute',
                    left: (200 * scale - 194) + 'px',
                    top: (400 * scale - 360) + 'px',
                    width: '375px',
                    height: '812px',
                    transform: 'scale(' + scale + ')',
                    border: 'none',
                }}
            />
            {loadingWidget}
        </Dialog>
    )
}

const DataDrivenPreview = ({ doc }) => {
    const [isOpen, setOpen] = useState(false)

    return (
        <Wrapper>
            <PreviewButton onClick={() => setOpen(true)}>
                Live Preview (of last saved version)
            </PreviewButton>
            <PreviewDialog
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                doc={doc}
            />
        </Wrapper>
    )
}

export default DataDrivenPreview
