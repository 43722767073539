import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import {
    Dialog,
    Button,
    Classes,
    Intent,
    FormGroup,
    ControlGroup,
    InputGroup,
    Icon,
    Spinner,
    Checkbox,
} from '@blueprintjs/core'
import styled from 'styled-components'
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

import { documentState } from 'v2/components/manager/document'
import { useContextTools } from 'v2/components/manager/api'
import config from 'config'

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
}

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    // width: 200,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
}

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    flexDirection: 'column',
    // overflow: 'hidden',
}

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
}

const ImageSection = styled.section`
    padding: 10px;
    max-width: 300px;
    flex: 1;

    color: rgba(0, 0, 0, 0.5);

    text-align: center;

    overflow: hidden;

    .dropzone {
        padding: 10px;
        background: #f5f8fa;

        border: 1px dashed #5c7080;
        border-radius: 6px;
    }
`

const PreviewWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    font-size: 12px;

    .preview,
    .actions {
        padding: 4px;
    }

    .empty {
        display: flex;
        align-items: center;
        .bp3-icon {
            margin-right: 10px;
        }
    }

    img {
        max-height: 100px;
        max-width: 100px;
    }
`

const generateAltText = async (img, dispatch, setWaiting, setAltText, doc, force) => {
    const altUrl = `${config.nonSocketsApi.url}/ai/alt`

    if (
        (!img.base64 && !img.url) ||
        (img.alt && !force) ||
        (img.url && img.url.includes('${'))
    ) {
        return
    }

    setWaiting(force ? 'force' : true)

    const response = await fetch(altUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...img,
            doc,
        }),
    })

    const text = await response.text()
    setWaiting(false)

    if (response.ok) {
        setAltText(text)
    }

    else {
        dispatch({
            type: 'NAVIGATION_SET_MESSAGE',
            payload: {
                message: 'Error generating alt text. You can enter it manually or try again with the Retry button.',
                error: text,
                intent: 'WARNING',
            },
        })
    }
}

export const ImagePreview = ({ image, onClear, onClick }) => {
    const base64 = _.get(image, 'base64')
    const src = _.get(image, 'url', base64)

    if (src === undefined || src === '') {
        return (
            <PreviewWrapper>
                <div className="empty" onClick={onClick}>
                    <Icon icon="media" iconSize={24} color="#ccc" />
                    <span>No image available to preview</span>
                </div>
            </PreviewWrapper>
        )
    }

    return (
        <PreviewWrapper>
            <div className="preview" onClick={onClick}>
                <img src={src} alt="" />
            </div>
            {onClear !== undefined ? (
                <div className="actions">
                    <Button
                        text="Clear image"
                        icon="trash"
                        minimal="true"
                        onClick={onClear}
                    />
                </div>
            ) : null}
        </PreviewWrapper>
    )
}

export const ImageUploader = ({ onChange, image, hideThumbnails }) => {
    const [files, setFiles] = useState([])
    const [warning, setWarning] = useState('')
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxSize: 10 * 1024 * 1024,
        multiple: false,
        onDropRejected: (files) => {
            setWarning('Images must be smaller than 10MB. Please compress the image.')
            setFiles([])
        },
        onDropAccepted: (files) => {
            setWarning('')

            // Convert to base64
            let reader = new FileReader()
            reader.onloadend = () => {
                onChange(reader.result, files[0].type)
            }
            reader.readAsDataURL(files[0])

            // Set image preview
            setFiles(
                files.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const thumbs = files.map((file) => (
        <React.Fragment key={file.name}>
            <div style={thumb}>
                <div style={thumbInner}>
                    <img src={file.preview} style={img} alt="" />
                </div>
            </div>
            <div>
                {file.path} - {file.size} bytes
            </div>
        </React.Fragment>
    ))

    useEffect(() => {
        if (_.isEmpty(image) || _.get(image, 'base64') === '') {
            setFiles([])
        }
    }, [image])

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview))
        },
        [files]
    )

    return (
        <ImageSection>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div>
                    Drag 'n' drop an image here, or click to select a file.
                </div>
            </div>
            {!_.isEmpty(thumbs) && !hideThumbnails ? (
                <aside style={thumbsContainer}>{thumbs}</aside>
            ) : null}
            {warning !== '' ? <aside>{warning}</aside> : null}
        </ImageSection>
    )
}

const ImageEditor = ({ initialImage, updatePath }) => {
    const [ isOpen, setOpen ] = useState(false)
    const [ img, setImg ] = useState({})
    const [ url, setUrl ] = useState('')
    const [ altText, setAltText ] = useState('')
    const [ waiting, setWaiting ] = useState(null)
    const [ useContext, setUseContext ] = useState(false)
    const { updateStyle } = useContextTools()
    const doc = useRecoilValue(documentState)
    const dispatch = useDispatch()
    let preview
    let altTextField

    const _uploadFile = (base64, mimeType) => setImg({ base64 })
    const _linkFile = () => setImg({ url })

    const _handleClose = () => {
        setOpen(false)
        setImg({})
    }

    const _confirm = () => {
        updateStyle({
            ...img,
            alt: altText,
        }, updatePath)

        _handleClose()
    }

    const _initDialog = () => {
        setImg(initialImage)
        setUrl(initialImage.url || '')
        setAltText(initialImage.alt || '')

        if (initialImage.alt) {
            setWaiting(false)
        }
    }

    useEffect(() => {
        generateAltText(img, dispatch, setWaiting, setAltText, useContext ? doc : null)
    }, [ img, dispatch ])

    const helper = (
        <React.Fragment>
            This text was drafted by {' '}
            <a href="https://www.anthropic.com/claude" target="_blank" rel="noreferrer">
                Claude 3 Sonnet
            </a>
            {' '} but may have been edited afterwards.
            <Button
                icon="refresh"
                disabled={img.url && img.url.includes('${')}
                onClick={() => generateAltText(img, dispatch, setWaiting, setAltText, useContext ? doc : null, true)}
                style={{ marginLeft: '10px' }}
            >
                Retry
            </Button>
        </React.Fragment>
    )

    if (waiting === false || waiting === 'force') {
        const src = img.url || img.base64

        preview = (
            <React.Fragment>
                <hr />
                <div style={{ textAlign: 'center' }}>
                    <img src={src} alt="" style={{ maxWidth: '300px' }} />
                </div>
            </React.Fragment>
        )
    }

    if (waiting === true || waiting === 'force') {
        altTextField = (
            <Spinner />
        )
    }

    else if (waiting === false) {
        altTextField = (
            <div>
            <FormGroup
                label="Alt Text"
                labelFor="alt"
                labelInfo="(required)"
                helperText={helper}
            >
                <InputGroup
                    id="alt"
                    value={altText}
                    onChange={(e) => setAltText(e.currentTarget.value)}
                />
            </FormGroup>
            <Checkbox
                label="Include page context"
                checked={useContext}
                onChange={event => setUseContext(event.currentTarget.checked)}
            />
            </div>
        )
    }

    return (
        <React.Fragment>
            <ImagePreview
                image={initialImage}
                onClick={() => {
                    setOpen(true)
                }}
            />

            <Dialog
                icon="media"
                onOpening={_initDialog}
                onClose={_handleClose}
                title="Image"
                isOpen={isOpen}
                style={{ width: '640px' }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ImageUploader
                            hideThumbnails
                            onChange={_uploadFile}
                        />

                        <p style={{ marginRight: '10px' }}>
                            OR
                        </p>

                        <FormGroup
                            label="Image URL"
                            labelFor="url"
                            style={{ flex: 1 }}
                        >
                            <ControlGroup>
                                <InputGroup
                                    fill
                                    id="url"
                                    placeholder="https://example.com/example.png"
                                    value={url}
                                    onChange={(e) => setUrl(e.currentTarget.value)}
                                />
                                <Button
                                    icon="arrow-down"
                                    disabled={!url.trim()}
                                    onClick={_linkFile}
                                />
                            </ControlGroup>
                        </FormGroup>
                    </div>

                    {preview}
                    {altTextField}
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button minimal onClick={_handleClose}>
                            Cancel
                        </Button>

                        <Button
                            minimal
                            intent={Intent.PRIMARY}
                            disabled={!(img.url || img.base64) || !altText.trim()}
                            onClick={_confirm}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default ImageEditor
